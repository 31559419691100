import React from 'react'

import {
  PageLayout, CartOverview } from '../components'

const CartTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <PageLayout lang={lang} switcher={page} className="shopping-bag">
      <CartOverview lang={lang} />
    </PageLayout>
  )
}

export default CartTemplate
